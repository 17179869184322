


















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class CashAuditDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    minutes: "",
    price: ""
  };
  loading: boolean = false;
  rules: any = {
    price: [{ required: true, message: "请输入租金", trigger: "blur" }],
    minutes: [{ required: true, message: "请输入时长", trigger: "blur" }]
  };
  show(data?: any) {
    this.dialogFormVisible = true;
    this.formData = {
      minutes: data ? data.minutes : "",
      price: data ? data.price : ""
    };
  }
  confirm() {
    (this.$refs["auditForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.dialogFormVisible = false;
        this.$emit("confirm", this.formData);
      }
    });
  }
}
