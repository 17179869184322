












































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import RentPriceDialog from "./components/RentPriceDialog.vue";

@Component({
  components: {
    RentPriceDialog
  }
})
export default class DeviceTypeEdit extends Vue {
  pagerHeader: any = {
    title: "添加/修改设备型号",
    desc: "添加/修改设备型号",
    breadcrumb: ["设备管理", "设备型号"]
  };
  activeName: string = "form1";
  loading: boolean = false;
  formData: any = {
    category: "",
    interactorType:"",
    name: "",
    imageUrl: "",
    desc: "",
    purchasePrice: "",
    originalPrice: "",
    price: "",
    parentCommission: "",
    topParentCommission: "",
    salesCount: "",
    stock: "",
    descImages: [],
    onceAbrasionRate: 0,
    deposit: "",
    isActive: true,
    rentPrice: "",
    rentTimeUnit: "",
    maxDayRent: "",
    freeMinutes: "",
    slotCount: "",
    annualFree: "",
    rentPrices: [],
    sort: 0,
    isForbidSale: false,
    id: 0
  };
  rules: any = {
    category: [{ required: true, message: "选择设备类型", trigger: "change" }],
    name: [{ required: true, message: "请输入名称", trigger: "blur" }],
    imageUrl: [{ required: true, message: "请上传图片", trigger: "change" }],
    purchasePrice: [
      { required: true, message: "请输入成本价", trigger: "blur" }
    ],
    originalPrice: [{ required: true, message: "请输入原价", trigger: "blur" }],
    price: [{ required: true, message: "请输入销售价", trigger: "blur" }],
    sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
    parentCommission: [
      { required: true, message: "请输入一级返佣", trigger: "blur" }
    ],
    topParentCommission: [
      { required: true, message: "请输入二级返佣", trigger: "blur" }
    ],
    salesCount: [{ required: true, message: "请输入销量", trigger: "blur" }],
    stock: [{ required: true, message: "请输入库存", trigger: "blur" }],
    deposit: [{ required: true, message: "请输入租赁押金", trigger: "blur" }],
    rentPrice: [{ required: true, message: "请完善租聘费用", trigger: "blur" }],
    maxDayRent: [
      { required: true, message: "请输入日封顶费", trigger: "blur" }
    ],
    freeMinutes: [
      { required: true, message: "请输入免费分钟数", trigger: "blur" }
    ],
    slotCount: [{ required: true, message: "请输入设备口数", trigger: "blur" }],
    annualFree: [
      { required: true, message: "请输入设备年费", trigger: "blur" }
    ],
    interactorType:[ { required: true, message: "请选择交互类型", trigger: "blur" }],
    rentPrices: [
      {
        validator: function(rule: any, value: any, callback: Function) {
          if (rule.length == 0) {
            callback(new Error("请添加设备套餐"));
          } else {
            callback();
          }
        },
        message: "请添加设备套餐"
      }
    ]
  };
  get categoryOptions() {
    return this.getEnum("DeviceCategory");
  }
  get DeviceInteractorType(){
      return this.getEnum('DeviceInteractorType')
  }
  get rentTimeUnitOptions() {
    return this.getEnum("ChargingBatteryRentTimeUnit");
  }
  handleBannerImageSuccess(res: any, file: any) {
    // this.imageUrl = URL.createObjectURL(file.raw);
    console.log(res);
    if (res.success) this.formData.imageUrl = res.result.fileUrl;
  }
  handleContentImageSuccess(res: any, file: any) {
    if (res.success) this.formData.descImages.push(res.result.fileUrl);
  }
  beforeUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      this.$message.error("上传头像图片大小不能超过 2MB!");
    }
    return isLt2M;
  }
  imageContentMove(index: number, mvIndex: number) {
    var img = this.formData.descImages.splice(index, 1);
    this.formData.descImages.splice(mvIndex, 0, img[0]);
  }
  deleteContentImage(index: number) {
    this.formData.descImages.splice(index, 1);
  }
  submitForm(formName: string) {
    console.log(this.formData);
    
    (this.$refs["deviceTypeForm1"] as any).validate(async (valid: boolean) => {
      if (valid) {
        (this.$refs["deviceTypeForm2"] as any).validate(
          async (valid: boolean) => {
            if (valid) {
              if (this.formData.category == 1) {
                if (this.formData.rentPrices.length == 0) {
                  this.activeName = "form2";
                  return void this.$notify.warning("请添加充电线租金套餐");
                }
              }

              (this.$refs["deviceTypeForm3"] as any).validate(
                async (valid: boolean) => {
                  if (valid) {
                    var formData = this.formData;
                    if (this.formData.category == 1) {
                      formData = {
                        ...formData,
                        rentPrice: 0,
                        rentTimeUnit: 0,
                        maxDayRent: 0,
                        freeMinutes: 0,
                        slotCount: 0,
                        annualFree: 0,
                        deposit: 0
                      };
                    } else if (this.formData.category == 3) {
                        formData = {
                          ...formData,
                          rentPrice: 0,
                          rentTimeUnit: 0,
                          maxDayRent: 0,
                          freeMinutes: 0,
                          slotCount: 0,
                          annualFree: 0,
                          deposit: 0,

                      };
                    }
                    this.loading = true;
                    let res;
                    try {
                      if (!formData.id) {
                        res = await this.$ajax.post(
                          "/api/services/app/DeviceType/Create",
                          formData
                        );
                        if (res.data.success) {
                          this.$notify({
                            title: "成功",
                            message: "修改成功！",
                            type: "success"
                          });
                          this.closeCurrTabAndGoToUrl("");
                        }
                      } else {
                        res = await this.$ajax.put(
                          "/api/services/app/DeviceType/Update",
                          formData
                        );
                        if (res.data.success) {
                          this.$notify({
                            title: "成功",
                            message: "修改成功！",
                            type: "success"
                          });
                          this.closeCurrTabAndGoToUrl("");
                        }
                      }
                    } catch {}
                    this.loading = false;
                  } else {
                    this.activeName = "form3";
                  }
                }
              );
            } else {
              this.activeName = "form2";
            }
          }
        );
      } else {
        this.activeName = "form1";
      }
    });
  }
  created() {
    if (this.$route.query.id) {
      this.readerEditInfo(parseInt(this.$route.query.id as string));
    }
  }
  async activated() {
    if (this.$route.query.id != this.formData.id) {
      if (this.$route.query.id) {
        this.readerEditInfo(parseInt(this.$route.query.id as string));
      } else {
        this.formData = {
          category: "",
          interactorType: "",
          name: "",
          imageUrl: "",
          desc: "",
          purchasePrice: "",
          originalPrice: "",
          price: "",
          parentCommission: "",
          topParentCommission: "",
          salesCount: "",
          stock: "",
          descImages: [],
          onceAbrasionRate: 0,
          isActive: true,
          deposit: "",
          rentPrice: "",
          rentTimeUnit: "",
          rentPrices: [],
          maxDayRent: "",
          freeMinutes: "",
          slotCount: "",
          annualFree: "",
          id: 0
        };
      }
    }
  }
  async readerEditInfo(id: number) {
    var loading = this.$loading({ text: "加载中..." });
    try {
      var res = await this.$ajax.get("/api/services/app/DeviceType/Get", {
        params: {
          id
        }
      });
      if (res.data.success) {
        var data = res.data.result;
        data.descImages = data.descImages || [];
        data.rentPrices = data.rentPrices || [];

        this.formData = data;
      }
    } catch (error) {}
    loading.close();
  }
  rentPriceConfirm(rentPriceItem: any) {
    if (this.editIndex == -1) {
      this.formData.rentPrices.push(rentPriceItem);
    } else {
      this.formData.rentPrices.splice(this.editIndex, 1, rentPriceItem);
    }
  }
  editIndex: number = -1;
  editRentPrice(row: any, index: number) {
    console.log(index);
    this.editIndex = index;
    (this.$refs.rentPriceDialog as RentPriceDialog).show(row);
  }
  addRentPrice() {
    this.editIndex = -1;
    (this.$refs.rentPriceDialog as RentPriceDialog).show(null);
  }
  deleteRentPrice(row: any, index: number) {
    this.formData.rentPrices.splice(index, 1);
  }
}
